import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Divider } from 'primereact/divider';
import 'primereact/resources/themes/saga-blue/theme.css'; // Choose a PrimeReact theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import cvData from '../data/cv.json'; // Adjust the path based on where your JSON file is located
import { Image } from 'primereact/image';

// Define the structure of the CV data
interface CVData {
  name: string;
  phone: string;
  email: string;
  linkedin: string;
  github: string;
  facebook: string;
  instagram: string;
  youtube: string;
  dob: string;
  summary: string[];
  portfolio: { title: string; description: string; url: string }[];
  skills: string[];
  experience: { title: string; company: string; url: string; duration: string; description: string[] }[];
  projects: { title: string; description: string }[];
  publications: string[];
  education: string[];
  interests: string[];
}

// This component uses the imported JSON data
const ProfileView: React.FC = () => {
  return (
    <div className="p-m-3 p-p-2 p-d-flex p-flex-column p-align-center">
      <br />
    <Image src="3.jpg" width="200" style={{margin: 10, borderRadius: 10}}/>
      <Card className="p-shadow-3 p-mb-3" title={cvData.name} subTitle={"Senior Software Engineer"}>
        <p>{cvData.phone} | {cvData.email}</p>
        <div className="p-d-flex p-jc-center p-ai-center p-mb-3">
          {cvData.linkedin && <Button icon="pi pi-linkedin" className="p-button-rounded p-button-text p-button-linkedin" tooltip="LinkedIn" tooltipOptions={{ position: 'top' }} onClick={() => window.open(cvData.linkedin, '_blank')} />}
          {cvData.github && <Button icon="pi pi-github" className="p-button-rounded p-button-text p-button-secondary" tooltip="GitHub" tooltipOptions={{ position: 'top' }} onClick={() => window.open(cvData.github, '_blank')} />}
          {cvData.facebook && <Button icon="pi pi-facebook" className="p-button-rounded p-button-text p-button-help" tooltip="Facebook" tooltipOptions={{ position: 'top' }} onClick={() => window.open(cvData.facebook, '_blank')} />}
          {cvData.instagram && <Button icon="pi pi-instagram" className="p-button-rounded p-button-text p-button-warning" tooltip="Instagram" tooltipOptions={{ position: 'top' }} onClick={() => window.open(cvData.instagram, '_blank')} />}
          {cvData.youtube && <Button icon="pi pi-youtube" className="p-button-rounded p-button-text p-button-danger" tooltip="YouTube" tooltipOptions={{ position: 'top' }} onClick={() => window.open(cvData.youtube, '_blank')} />}
        </div>
      </Card>

      {/* Summary Section */}
      <Card className="p-shadow-3 p-mb-3" title="Summary">
        {cvData.summary.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </Card>

      {/* Portfolio Section */}
      <Card className="p-shadow-3 p-mb-3" title="Portfolio">
        {cvData.portfolio.map((item, index) => (
          <div key={index} className="p-mb-3">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            <Button label="Visit" icon="pi pi-external-link" className="p-button-link" onClick={() => window.open(item.url, '_blank')} />
            <Divider />
          </div>
        ))}
      </Card>

      {/* Skills Section */}
      <Card className="p-shadow-3 p-mb-3" title="Skills">
        <div className="p-d-flex p-flex-wrap">
          {cvData.skills.map((skill, index) => (
            <Tag key={index} value={skill} className="p-m-1" />
          ))}
        </div>
      </Card>

      {/* Experience Section */}
      <Card className="p-shadow-3 p-mb-3" title="Experience">
        {cvData.experience.map((exp, index) => (
          <div key={index} className="p-mb-3">
            <h3>{exp.title} @ <a href={exp.url} target="_blank" rel="noopener noreferrer">{exp.company}</a></h3>
            <p>{exp.duration}</p>
            <ul>
              {exp.description.map((desc, i) => (
                <li key={i}>{desc}</li>
              ))}
            </ul>
            <Divider />
          </div>
        ))}
      </Card>

      {/* Projects Section */}
      <Card className="p-shadow-3 p-mb-3" title="Projects">
        {cvData.projects.map((project, index) => (
          <div key={index} className="p-mb-3">
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <Divider />
          </div>
        ))}
      </Card>

      {/* Publications Section */}
      <Card className="p-shadow-3 p-mb-3" title="Publications">
        {cvData.publications.map((pub, index) => (
          <p key={index}>{pub}</p>
        ))}
      </Card>

      {/* Education Section */}
      <Card className="p-shadow-3 p-mb-3" title="Education">
        {cvData.education.map((edu, index) => (
          <p key={index}>{edu}</p>
        ))}
      </Card>

      {/* Interests Section */}
      <Card className="p-shadow-3 p-mb-3" title="Interests">
        <ul>
          {cvData.interests.map((interest, index) => (
            <li key={index}>{interest}</li>
          ))}
        </ul>
      </Card>
    </div>
  );
};

export default ProfileView;
