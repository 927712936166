import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { useRef } from 'react';

interface ContactFormProps {}

const ContactForm: React.FC<ContactFormProps> = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const toast = useRef<Toast>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};

        if (!formData.name) newErrors.name = 'Name is required.';
        if (!formData.email) newErrors.email = 'Email is required.';
        if (!formData.message) newErrors.message = 'Message is required.';

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.current?.show({ severity: 'error', summary: 'Validation Error', detail: 'Please fill out all fields.', life: 3000 });
            return;
        }

        try {
            const response = await fetch('/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Message sent successfully!', life: 3000 });
                setFormData({ name: '', email: '', message: '' });
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to send message. Please try again later.', life: 3000 });
            }
        } catch (error) {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Network error. Please try again later.', life: 3000 });
        }
    };

    const isFormFieldValid = (name: string) => !!errors[name];
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) ? <small className="p-error">{errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <div className="p-card p-4" style={{padding: 20}}>
            <Toast ref={toast} />
            <h4>Contact Me</h4>
            <form onSubmit={handleSubmit} className="p-fluid">
                <div className="p-field">
                    <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>Name</label>
                    <InputText
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className={classNames({ 'p-invalid': isFormFieldValid('name') })}
                    />
                    {getFormErrorMessage('name')}
                </div>
                <div className="p-field">
                    <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>Email</label>
                    <InputText
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className={classNames({ 'p-invalid': isFormFieldValid('email') })}
                    />
                    {getFormErrorMessage('email')}
                </div>
                <div className="p-field">
                    <label htmlFor="message" className={classNames({ 'p-error': isFormFieldValid('message') })}>Message</label>
                    <InputTextarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        rows={5}
                        className={classNames({ 'p-invalid': isFormFieldValid('message') })}
                    />
                    {getFormErrorMessage('message')}
                </div>
                <Button icon="pi pi-send" type="submit" label="Send Me a Message" className="p-mt-2 btn-color" />
            </form>
        </div>
    );
};

export default ContactForm;
