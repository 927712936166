import React, { useEffect, useState } from "react";
import CVComponent from "./cv";
import cvData from "../data/cv.json";
import { Panel } from "primereact/panel";
import { Tag } from "primereact/tag";
import  ContactForm from "./contact";
import ProjectList from "./portfolio"
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Button } from "primereact/button";
import Header from "./header";
import CVComponentPDF from "./cv_pdf";

type Props = {};

const Body = (props: Props) => {
  const [data, setData] = useState<any | null>(null);

  useEffect(() => {
    setData(cvData as any); // Load the data from JSON file
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }


    // Function to generate PDF
    const generatePDF = () => {
      const element = document.getElementById('main-body') as any; // Select the content to be converted into PDF
      html2canvas(element, { scale: 1 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [canvas.width, canvas.height],
        });
  
        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
        pdf.save('christian_maccabe.pdf');
      });
    };

  return (
    <>
    <div className="body">
      <div className="left-container">
        <Panel header="Technical Skills" className="mt-4">
          {data.skills.map((skill: any, index: any) => (
            <Tag
              key={index}
              value={skill}
              className="mr-2 mb-2 btn-color"
              style={{ margin: 2 }}
            />
          ))}
        </Panel>
        <br />
        {/* <Button label="Download CV" icon="pi pi-file-pdf" size="small" onClick={generatePDF} className="mt-4"  style={{width: '100%', backgroundColor: '#7BA292', marginBottom: 10}}/> */}

        <br />
        <Panel header="Portfolio">
        <ProjectList />
        </Panel>

        <br />
        <ContactForm />
      </div>
      <div className="right-container">
        <CVComponent />
      </div>
    </div>

    <div id="main-body"  style={{position: 'absolute', left: '100000px', bottom: 0, width: '1000px', backgroundColor : 'white'}}>
          <Header />
          <br />
      <CVComponentPDF />

      <br />
        <Panel header="Technical Skills" className="mt-4">
          <ul>
          {data.skills.map((skill: any, index: any) => (
            <li
              key={index}
             
            >{skill}</li>
          ))}
          </ul>
        </Panel>
        <br />
        <Panel header="Portfolio">
        <ProjectList />
        </Panel>

      </div>
     

    </>
  );
};

export default Body;
