import React, { useEffect, useState } from "react";
import cvData from "../data/cv.json";
import { Panel } from "primereact/panel";

type Props = {};

const Header = (props: Props) => {
  const [data, setData] = useState<any | null>(null);

  useEffect(() => {
    setData(cvData as any); // Load the data from JSON file
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }
  return (
    <div className="header">
      <div style={{width: '30%'}}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", padding: 20, }}>
            <img
              src="3.jpg"
              className="shw"
              width="175"
              style={{ borderRadius: 10 }}
            />
          </div>

          <div
            style={{ width: "45%", paddingTop: 10 }}
          >
            <h2>{data.name}</h2>
            <h4 style={{ color: "#7ba292", marginTop: -10 }}>
              Senior Software Engineer
            </h4>
            <p>
              <strong>
                <i className="pi pi-phone"></i>
              </strong>{" "}
              &nbsp;&nbsp;{data.phone}
            </p>
            <p>
              <strong>
                <i className="pi pi-envelope"></i>
              </strong>
              &nbsp;&nbsp; {data.email}
            </p>
            <hr />
            <div style={{ width: "100%", display: "flex" }}>
              <a target="_blank" href={data.linkedin} className="linkIcon">
                <i
                  className="pi pi-linkedin"
                  style={{ fontSize: 25, color: "black" }}
                ></i>
              </a>

              <a target="_blank" href={data.github} className="linkIcon">
                <i
                  className="pi pi-github"
                  style={{ fontSize: 25, color: "black" }}
                ></i>
              </a>

              <a target="_blank" href={data.instagram} className="linkIcon">
                <i
                  className="pi pi-instagram"
                  style={{ fontSize: 25, color: "black" }}
                ></i>
              </a>

              <a target="_blank" href={data.youtube} className="linkIcon">
                <i
                  className="pi pi-youtube"
                  style={{ fontSize: 25, color: "black" }}
                ></i>
              </a>
              <a target="_blank" href={data.facebook} className="linkIcon">
                <i
                  className="pi pi-facebook"
                  style={{ fontSize: 25, color: "black" }}
                ></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "60%",
          textAlign: "justify",
          paddingLeft: "50px",
          paddingRight: "60px",
          fontSize: "20px",
          marginTop: 10,
          paddingTop: 0,
        }}
      >
        {data.summary.map((s: any, index: any) => (
          <p key={index}>{s}</p>
        ))}
      </div>
    </div>
  );
};

export default Header;
