import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Timeline } from 'primereact/timeline';
import cvData from '../data/cv.json'; 

interface CVData {
  name: string;
  phone: string;
  email: string;
  linkedin: string;
  dob: string;
  summary: string[];
  skills: string[];
  experience: Array<{
    title: string;
    icon: string;
    company: string;
    duration: string;
    url?: string;
    description: string[];
  }>;
  projects: Array<{
    title: string;
    description: string;
  }>;
  publications: string[];
  education: string[];
  interests: string[];
}

const CVComponentPDF: React.FC = () => {
  const [data, setData] = useState<CVData | null>(null);

  useEffect(() => {
    setData(cvData as CVData); // Load the data from JSON file
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4">

      <Panel header="Work Experience"  style={{textAlign: 'left', backgroundColor: 'white'}}>
        {data.experience.map((exp, index) => (
          <Card key={index} title={<><i className={`pi ${exp.icon}`} style={{fontSize: 25}}></i>&nbsp;&nbsp;{exp.title}&nbsp;&nbsp;- {exp.company}</>} subTitle={exp.duration} 
            style={{margin: 5,backgroundColor: 'white'}}>
            <div style={{textAlign: 'left', width: '90%', backgroundColor: 'white'}}>
            <ul>
              {exp.description.map((desc, idx) => (
                <li key={idx}>{desc}</li>
              ))}
            </ul>
             </div>
            {exp.url ? <a className="link-color" href={exp.url} target="_blank"></a> : null}
          </Card>
        ))}
      </Panel>
        <br />
      <Panel header="Key Projects" className="mt-4" style={{textAlign: 'left', backgroundColor: 'white'}}>
        {data.projects.map((project, index) => (
          <Card key={index} title={project.title} className="mb-4" style={{backgroundColor: 'white'}}>
            <div  style={{textAlign: 'left', width: '90%'}}>            
              <p>{project.description}</p>
            </div>
          </Card>
        ))}
      </Panel>
        <br />
      <Panel header="Publications" className="mt-4">
        <ul className='publication-list'>
          {data.publications.map((pub, index) => (
            <li key={index}>{pub}</li>
          ))}
        </ul>
      </Panel>
          <br />
      <Panel header="Education" className="mt-4">
        <ul className='education-list'>
          {data.education.map((edu, index) => (
            <li key={index}>{edu}</li>
          ))}
        </ul>
      </Panel>
          <br />
      <Panel header="Interests" className="mt-4" style={{marginBottom: 25}}>
        {data.interests.join(', ')}
      </Panel>
      <br />

    </div>
  );
};

export default CVComponentPDF;
