import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

interface Project {
    title: string;
    description: string;
    url: string;
    role?: string;
}

const projects: Project[] = [
    {
        title: "Power Integrator",
        description: "Power Integrator is a platform that facilitates seamless integration with ERP systems.",
        role:"Senior Developer",
        url: "https://www.powerintegrator.eu"
    },
    {
        title: "Codeplant",
        role: "Founder and Developer",
        description: "Codeplant is an educational platform that integrates AI and game theory to equip learners with technology skills.",
        url: "https://site.codeplant.co.za"
    },
    {
        title: "AI Images",
        role: "Lead Developer",
        description: "An AI-powered image generator that creates unique images based on user input. Also an educational tool to teach about Art and AI.",
        url: "https://images.codeplant.co.za"
    },
    {
        title: "The Dev Space",
        role: "Web and AI Developer",
        description: "The Dev Space is an AI powered website for a YouTube channel that focuses on technology, coding, and software development.",
        url: "https://thedevspace.net"
    }
];

const ProjectList: React.FC = () => {
    return (
        <div className="p-grid p-justify-center p-align-center project-list-container">
            {projects.map((project, index) => (
                <div className="p-col-12 p-md-4" key={index}>
                    <Card
                        title={<div>{project.title}</div>}
                        className="project-card"
                        subTitle={project.role}
                        footer={
                            <Button link label="Visit" icon="pi pi-external-link" style={{marginLeft: 20}} onClick={() => window.open(project.url, "_blank")} />
                        }
                        >
                        <p style={{margin: 0, padding: 5}}>{project.description}</p>
                    </Card>
                </div>
            ))}
        </div>
    );
};

export default ProjectList;
