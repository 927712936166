import 'primeicons/primeicons.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import Body from './components/body';
import ProfileView from './components/profile';

function App() {
  // find out if we are on mobile
  const isMobile = window.innerWidth < 600;

  return (
    <div style={{width: '100vw', margin: 'auto'}}>
      {!isMobile ? (<><Header />
      <Body />
    <Footer /></>) : <ProfileView />}
    </div>
  );
}

export default App;
