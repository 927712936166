import React from 'react'

type Props = {}

const Footer = (props: Props) => {
  return (
    <div className='footer'>
      
    </div>
  )
}

export default Footer